<template>
    <div class="wellness page">
        <b-container fluid="xl">
            <b-row>
                <b-col cols="12">
                    <router-link class="go-back" :to="{name: 'services'}">
                        <img src="@/assets/images/icons/ic-arrow-right-black-big.svg" />
                        {{tr('fitness_go_back')}}
                    </router-link>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" md="6">
                    <h2 class="animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up')"><span class="gold-lined-text animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-1')">Fitness</span></h2> 
                    <div class="wellness__text">
                        <p class="page-lead animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-2')">{{tr('fitness_title')}}</p>                                           
                    </div>
                </b-col>
                <b-col cols="12" md="6">
                    <img :src="baseUrl + 'pictures/fitness/gallery/gym-03.jpg'" alt="Luminor Apartments" class="w-100 animateScroll" v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 'fade-up', 'delay-2')" />                             
                </b-col>
            </b-row>            
        </b-container>
        <TakeLookInside  :flatslides="wellnessImgs" :flatslidesBig="[]" :showTitle="false" />
    </div>
</template>

<script>
import TakeLookInside from '@/components/pages/TakeLookInside.vue'

export default {
    data(){
        return{
            wellnessImgs: [
                "/pictures/fitness/gallery/gym-01.jpg",
                "/pictures/fitness/gallery/gym-02.jpg",
                "/pictures/fitness/gallery/gym-03.jpg"                
            ]
        }
    },
    components:{
        TakeLookInside
    }
}
</script>